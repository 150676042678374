<template>
  <div class="main">
    <div class="title">摄像头信息</div>
    <!-- 查询 -->
    <div class="search">
      <el-form
        :inline="true"
        :model="formData"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-form-item label="录像设备序列号：">
          <el-input
            v-model="formData.cameraSn"
            clearable
            placeholder="请输入录像设备序列号"
          ></el-input>
        </el-form-item>
        <el-form-item label="录像设备项目id：">
          <el-input
            v-model="formData.cameraProjectId"
            clearable
            placeholder="请输入录像设备项目id"
          ></el-input>
        </el-form-item>
        <el-form-item label="录像设备产品code：">
          <el-input
            v-model="formData.cameraProductCode"
            clearable
            placeholder="请输入录像设备项目id"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-button type="primary" style="height: 40px" @click="onSubmit"
        >查询</el-button
      >
    </div>
    <!-- 表格部分 -->
    <div class="table">
      <div class="table-header">
        <p class="table-header-title">摄像头信息列表</p>
        <el-button type="primary" @click="addDevice">新增摄像头</el-button>
      </div>
      <el-table
        :data="tableData"
        border
        :header-cell-style="{ color: 'rgba(0, 0, 0, 0.85)' }"
        style="width: 100%; color: rgba(0, 0, 0, 0.85)"
      >
        <el-table-column type="index" label="序号" width="50" align="center">
        </el-table-column>
        <el-table-column prop="deviceName" label="设备名称" align="center">
        </el-table-column>
        <el-table-column
          label="录像设备序列号"
          show-overflow-tooltip
          align="center"
          width="200"
          prop="cameraSn"
        >
        </el-table-column>
        <el-table-column
          prop="cameraProjectId"
          label="录像设备项目id"
          show-overflow-tooltip
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="cameraProductCode"
          label="录像设备产品code"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="action" label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleEdit(scope.$index, scope.row)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              disabled
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="paging">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="currentPage" :page-sizes="[10, 20, 30, 40]" :page-size="100"
                    layout="total, sizes, prev, pager, next, jumper" :total="totalPage" style="
            background-color: #fff;
            text-align: right;
            padding: 0px !important;
          ">
                </el-pagination>
            </div>
    </div>
    <!-- 新增/编辑设备弹框 -->
    <el-dialog
      :title="isEdit ? '编辑摄像头' : '新增摄像头'"
      :visible.sync="dialogFormVisible"
      :before-close="closeExpertFormDialog"
      width="700px"
    >
      <el-form :model="form" label-width="145px" ref="form">
        <el-form-item label="绑定设备：">
          <!-- <el-input v-model="form.deviceId" autocomplete="off"></el-input> -->
          <el-select
            v-model="form.deviceId"
            filterable
            placeholder="请选择绑定的设备"
            class="select-project"
            @change="changeProject($event)"
            style="width: 515px"
          >
            <el-option
              v-for="item in deviceList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id"
              style="color: rgb(195, 226, 249)"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="摄像头连接方式：">
          <!-- <el-input v-model="form.deviceId" autocomplete="off"></el-input> -->
          <el-select
            v-model="form.cameraConnectionMethod"
            filterable
            placeholder="请选择摄像头连接方式"
            class="select-project"
            style="width: 515px"
          >
            <el-option
              v-for="item in methodsList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              style="color: rgb(195, 226, 249)"
            ></el-option>
          </el-select>
        </el-form-item>
        <!---->
        <el-form-item
          label="录像设备序列号："
          v-if="form.cameraConnectionMethod == 0"
        >
          <el-input
            v-model="form.cameraSn"
            autocomplete="off"
            placeholder="请输入录像设备序列号"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="摄像头设备序列号："
          v-if="form.cameraConnectionMethod == 1"
        >
          <el-input
            v-model="form.cameraIpcSn"
            autocomplete="off"
            placeholder="请输入摄像头设备序列号："
          ></el-input>
        </el-form-item>
        <el-form-item label="录像设备项目id：">
          <el-input
            v-model="form.cameraProjectId"
            autocomplete="off"
            placeholder="请输入录像设备项目id"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="录像设备产品code：">
          <el-input
            v-model="form.cameraProductCode"
            autocomplete="off"
            placeholder="请输入录像设备产品code"
          >
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleDialogCancel()">取 消</el-button>
        <el-button type="primary" @click="handleDialogConfirm()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
  
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      formData: {
        // 录像设备序列号
        cameraSn: "",
        // 录像设备项目id
        cameraProjectId: "",
        // 录像设备产品code
        cameraProductCode: "",
      },
      currentPage: 1,
      pageSize: 10,
      totalPage: 10,
      tableData: [],
      tableDatas: [],
      dialogFormVisible: false,
      isEdit: false, //添加和编辑 默认添加
      form: {
        // 设备id
        deviceId: "",
        id: "",
        // 录像设备序列号
        cameraSn: "",
        // 摄像头设备序列号
        cameraIpcSn: "",
        // 录像设备项目id
        cameraProjectId: "",
        // 录像设备产品code
        cameraProductCode: "",
        //  摄像头连接方式
        cameraConnectionMethod: 0,
      },
      //   设备列表
      deviceList: [
        { id: 1, deviceName: "jj" },
        { id: 2, deviceName: "qq" },
        { id: 3, deviceName: "kk" },
      ],
      methodsList: [
        {
          label: "正常连接",
          value: 0,
        },
        {
          label: "直连",
          value: 1,
        },
      ],
    };
  },
  created() {
    this.listRequest();
  },
  mounted() {
    this.watchStorage();
  },
  methods: {
    // 监控locaStorage
    watchStorage() {
      const that = this;
      window.addEventListener("setItemEvent", function (e) {
        // 监听setitem的 key ，执行对应的业务逻辑
        // console.log(e.key, e.value);
        if (e.key === "projectID") {
          const newProjectId = e.value;
          that.listRequest(newProjectId);
        }
      });
    },
    // 请求摄像头列表接口
    listRequest(id) {
      let projectId;
      let projectID = localStorage.getItem("projectID");
      if (id) {
        projectId = id;
      } else {
        projectId = projectID;
      }
      var that = this;
      let param = {
        pageNum: that.currentPage,
        pageSize: that.pageSize,
        projectId:projectId,
        // 录像设备项目id
        cameraProjectId: this.formData.cameraProjectId,
        // 录像设备序列号
        cameraSn: this.formData.cameraSn,
        // 录像设备产品code
        cameraProductCode: this.formData.cameraProductCode,
      };
      this.$post("/deviceCamera/pageList", param)
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            this.tableData = res.data.list;
            this.tableDatas = this.tableData.filter(
              (item) => item.projectId == projectId
            );
            this.totalPage = res.data.total;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    // 获取设备id接口
    deviceIDRequest() {
      let param = {
        deviceState: 0,
        projectList: [localStorage.getItem("projectID")],
      };
      this.$post("/device/list", param)
        .then((res) => {
          if (res.code == 1) {
            console.log(res.data);
            this.deviceList = res.data.data;
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {
          console.log("异常");
        });
    },
    changeProject(value) {
      console.log(value);
      console.log(this.form.deviceId);
    },
    // 查询
    onSubmit() {
      this.listRequest();
    },
    // 新增设备
    addDevice() {
      // @ts-ignore
      //   cameraConnectionMethod:0
      this.form = {};
      this.dialogSelectedOptions = [];
      this.dialogFormVisible = true;
      console.log("add");
      this.isEdit = false;
      this.deviceIDRequest();
    },
    //编辑按钮
    handleEdit(index, row) {
      this.dialogFormVisible = true;
      this.isEdit = true;
      this.deviceIDRequest();
      this.$nextTick(() => {
        //赋值
        this.form = Object.assign({}, row);
      });
    },
    //删除按钮
    handleDelete(index, row) {
      this.$confirm("确定要删除此摄像头吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warnimg",
      })
        .then((status) => {
          if (status == "confirm") {
            // console.log(row);
            let param = {
              id: row.id,
            };
            this.$get("/deviceCamera/del", param)
              .then((response) => {
                if (response.code === "1") {
                  this.$message({
                    message: "删除设备成功!",
                    type: "success",
                    duration: 1000,
                  });
                  // 刷新整个页面
                  this.reload();
                } else {
                  this.$message({
                    message: response.msg,
                    type: "warning",
                    duration: 1000,
                  });
                }
              })
              .catch((error) => {
                this.$message({
                  message: error.msg,
                  type: "error",
                  duration: 1000,
                });
              });
          }
        })
        .catch((status) => {
          if (status == "cancel") {
            this.cancelLogout();
          }
        });
    },

    //关闭dialog前
    closeExpertFormDialog() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框取消按钮
    handleDialogCancel() {
      this.$nextTick(() => {
        // @ts-ignore
        this.$refs["form"].resetFields();
        this.dialogSelectedOptions = [];
      });
      this.dialogFormVisible = false;
      this.isEdit = false;
    },
    //编辑添加弹框确认按钮
    handleDialogConfirm() {
      if (
        this.form.deviceId == null ||
        this.form.cameraSn == null ||
        this.form.cameraProductCode == null ||
        this.form.cameraProjectId == null ||
        this.form.deviceId == "" ||
        this.form.cameraSn == "" ||
        this.form.cameraProductCode == "" ||
        this.form.cameraProjectId == ""
      ) {
        this.$message({
          message: "请填写完整信息!",
          type: "warning",
          duration: 1000,
        });
      } else {
        if (this.isEdit) {
          console.log(this.form.cameraProjectId);
          // 编辑操作
          let param = {
            // 设备id
            deviceId: this.form.deviceId,
            id: this.form.id,
            // 摄像头连接方式 0正常链接 1直连
            cameraConnectionMethod: this.form.cameraConnectionMethod,
            // 录像设备序列号
            cameraSn: this.form.cameraSn,
            // 摄像头设备序列号
            cameraIpcSn: this.form.cameraIpcSn,
            // 录像设备项目id
            cameraProjectId: this.form.cameraProjectId,
            // 录像设备产品code
            cameraProductCode: this.form.cameraProductCode,
            projectId:localStorage.getItem("projectID")
          };
          this.$post("/deviceCamera/saveOrUpdate", param)
            .then((response) => {
              if (response.code === "1") {
                this.$message({
                  message: "编辑摄像头成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                this.dialogFormVisible = false;
                this.$refs["form"].resetFields();
              } else {
                this.$message({
                  message: response.msg,
                  type: "warning",
                  duration: 1000,
                });
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
        } else {
          let param = {
            // 设备id
            deviceId: Number(this.form.deviceId),
            // 摄像头连接方式 0正常链接 1直连
            cameraConnectionMethod: this.form.cameraConnectionMethod,
            // 录像设备序列号
            cameraSn: this.form.cameraSn,
            // 摄像头设备序列号
            cameraIpcSn: this.form.cameraIpcSn,
            // 录像设备项目id
            cameraProjectId: Number(this.form.cameraProjectId),
            // 录像设备产品code
            cameraProductCode: this.form.cameraProductCode,
            projectId:localStorage.getItem("projectID")
          };
          this.$post("/deviceCamera/saveOrUpdate", param)
            .then((response) => {
              if (response.code === "1") {
                this.$message({
                  message: "添加设备成功!",
                  type: "success",
                  duration: 1000,
                });
                // 刷新整个页面
                this.reload();
                this.dialogFormVisible = false;
                this.$refs["form"].resetFields();
              } else {
                this.$message({
                  message: response.msg,
                  type: "warning",
                  duration: 1000,
                });
              }
            })
            .catch((error) => {
              this.$message({
                message: error.msg,
                type: "error",
                duration: 1000,
              });
            });
        }
        this.$nextTick(() => {
          // @ts-ignore
          this.$refs["form"].resetFields();
        });
        this.dialogFormVisible = false;
        this.isEdit = false;
      }
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.listRequest();
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.listRequest();
    },
  },
};
</script>
<style>
.el-input__inner {
  font-size: 13px !important;
}
</style>
<style lang="scss" scoped>
.main {
  margin: 10px;

  .search {
    margin: 20px auto;
    background-color: #fff;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 10px;
    box-sizing: border-box;

    .el-form {
      display: flex;
      margin-top: 20px;

      .el-form-item {
        display: flex;
        flex-wrap: wrap;

        /deep/ .el-form-item__content {
          vertical-align: middle !important;
          width: 180px !important;
        }

        /deep/ .el-form-item__label {
          color: rgba(0, 0, 0, 0.85) !important;
        }
      }
    }
  }

  .table {
    margin: 10px 0;
    background-color: #fff;
    padding: 10px;

    .table-header {
      display: flex;
      justify-content: space-between;
      padding: 10px 0px 0px;

      .table-header-title {
        padding: 10px 0px 0px;
      }
    }
    .el-table {
      margin: 20px auto;
    }
  }
}
</style>
  